import YokaiTitle from "components/YokaiTitle";
import React from "react";
import proba from "../media/proba.json";

const Ecosystem = () => {

  return (
    <div className={"ecosystem"}>
      <YokaiTitle>Ecosystem</YokaiTitle>
     
     <div className="ecosystem-list">
      
      <div className="ecosystem-item">
        
      </div>
     </div>
     


    </div>


  );
};

export default Ecosystem;
