import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/styles.scss";
import { createWeb3ReactRoot, Web3ReactProvider } from "@web3-react/core";

const Web3ProviderNetwork = createWeb3ReactRoot("NETWORK");

// if (!!window.ethereum) {
//     window.ethereum.autoRefreshOnNetworkChange = false
// }

ReactDOM.render(<App />, document.getElementById("root"));
