//Yokaichain
export const YOKAICHAINCONTRACTADDRESS = "0x59c7b16369422959eeb218c7270e3b5132cb1f28";
export const YOKAIHEROESMANAGERCONTRACTADDRESS = "0x547a28460a332d193a1487f1d7b6dabcb15d423a";

export const TWITTERLINK = "https://twitter.com/yokaichainnft";
export const MEDIUMLINK = "https://medium.com/@todaiworld";
export const DISCORDLINK = "https://discord.gg/up4paVmh8b";

export const PAINTSWAPLINK = "https://paintswap.finance/marketplace/assets/";

export const NETWORKS = {
  mainnet: {
    name: "FTM",
    color: "#ff8b9e",
    chainId: 250,
    rpcUrl: "https://rpc.ftm.tools/",
    blockExplorer: "https://ftmscan.com/",
    contractAddress: ""
  }
};

export const NETWORK = chainId => {
  for (const n in NETWORKS) {
    if (NETWORKS[n].chainId === chainId) {
      return NETWORKS[n];
    }
  }
};
